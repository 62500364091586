import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import Footer from './footer'
import '../styles/index.scss'
//import { navigate } from 'gatsby'
import SiteConfig from '../../config/SiteConfig.json'
import ScrollUpButton from 'react-scroll-up-button'


class Layout extends React.Component {
  render() {
    let id = "body"
    if(SiteConfig.brand === "BlueMail" || SiteConfig.brand === "BlueMail+"){
      id = "body_bm"
    }
    return (
    <div>
      <ScrollUpButton 
      style={{
        outline: "none",
        borderRadius: "10px",
        opacity: "0.1"
      }}
      />
      <div id={id} style={{backgroundColor: SiteConfig.content.body.backgroundColor}}>
        {this.props.children}
      </div>
      <Footer />
    </div>
    )
  }
}

export default Layout;