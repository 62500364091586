import React from 'react';
import { Jumbotron } from 'reactstrap';
import SiteConfig from '../../config/SiteConfig.json';


const Footer = () => (
  <Jumbotron id="footer" style={{backgroundColor: SiteConfig.content.footer.style.backgroundColor}}>
    <span ><a href={SiteConfig.seo.copyrightLink} style={{color: '#c2dbee'}}>{SiteConfig.seo.copyright}</a></span>
  </Jumbotron>
)

export default Footer