import React from "react";
import SiteConfig from "../../config/SiteConfig.json";
import { navigate, Link } from "gatsby";
import FA from "react-fontawesome";
import {
  en,
  es,
  de,
  ru,
  pt,
  ja,
  fr,
  it,
  cs,
  el,
  fi,
  hi,
  hr,
  hu,
  id,
  ko,
  nb,
  nl,
  pl,
  tr,
  he,
  ar,
  zh
} from "../../languages/layouts";
import CookieConsent from "react-cookie-consent";
import SideDrawer from "./Sidebar/SideDrawer/SideDrawer";
import BackDrop from "./Sidebar/Backdrop/Backdrop";
import {
  Jumbotron,
  Input,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  ListGroup
} from "reactstrap";
import Dropdown from "react-dropdown";

export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      lang: "en",
      text: en,
      data: "",
      value: "",
      hide: "",
      sideDrawerOpen: false
    };
    this.toggleNavBar = this.toggleNavBar.bind(this);
    this.handleMobileChange = this.handleMobileChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchValue = this.searchValue.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSearchButton = this.handleSearchButton.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  drawerToggleClickHandler = () => {
    this.setState(prevState => ({ sideDrawerOpen: !prevState.sideDrawerOpen }));
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  toggleNavBar() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
        return;
    };
  }

  componentDidMount() {
    let lang = this.props.lang;
    if (this.props.lang) {
      this.setState({ lang });
    }
    if (lang === "es") {
      this.setState({ text: es });
    } else if (lang === "de") {
      this.setState({ text: de });
    } else if (lang === "ru") {
      this.setState({ text: ru });
    } else if (lang === "pt") {
      this.setState({ text: pt });
    } else if (lang === "ja") {
      this.setState({ text: ja });
    } else if (lang === "fr") {
      this.setState({ text: fr });
    } else if (lang === "it") {
      this.setState({ text: it });
    } else if (lang === "cs") {
      this.setState({ text: cs });
    } else if (lang === "el") {
      this.setState({ text: el });
    } else if (lang === "fi") {
      this.setState({ text: fi });
    } else if (lang === "hi") {
      this.setState({ text: hi });
    } else if (lang === "hr") {
      this.setState({ text: hr });
    } else if (lang === "hu") {
      this.setState({ text: hu });
    } else if (lang === "id") {
      this.setState({ text: id });
    } else if (lang === "ko") {
      this.setState({ text: ko });
    } else if (lang === "nb") {
      this.setState({ text: nb });
    } else if (lang === "nl") {
      this.setState({ text: nl });
    } else if (lang === "pl") {
      this.setState({ text: pl });
    } else if (lang === "tr") {
      this.setState({ text: tr });
    } else if (lang === "he") {
      this.setState({ text: he });
    } else if (lang === "ar") {
      this.setState({ text: ar });
    }else if (lang === "zh") {
      this.setState({ text: zh });
    } else {
      this.setState({ text: en });
    }
  }

  handleChange(e) {
    let target = e.value;
    let currentLang = this.state.lang;
    let currentPathName = window.location.pathname;
    let newPath;

    if (this.props.path) {
      newPath = this.props.path.slice(0, -2);
      newPath = `${newPath}${target}`;
      window.location.replace(`${SiteConfig.url}/search${newPath}`);
    } else {
      if (currentPathName === "/" || currentPathName.length <= 4) {
        if (target !== "en") {
          newPath = `/${target}/`;
        } else {
          newPath = "/";
        }
      } else if (currentLang === "en" && currentPathName.length > 4) {
        if (target !== "en") {
          newPath = `/${target}${currentPathName}`;
        }
      } else {
        if (target !== "en") {
          newPath = currentPathName.replace(`/${currentLang}/`, `/${target}/`);
        } else {
          newPath = "/" + currentPathName.substring(4);
        }
      }
    }
    navigate(newPath);
  }

  handleKeyPress = e => {
    if (e.key === "Enter" && this.state.value !== "") {
      let searchUrl = `/search?${this.state.value}?lang=${this.state.lang}`;
      window.location.replace(`${SiteConfig.url}${searchUrl}`);
      this.setState({ value: "" });
      this.onBlur();
    }
  };

  handleSearchButton = () => {
    let searchUrl = `/search?${this.state.value}?lang=${this.state.lang}`;
    window.location.replace(`${SiteConfig.url}${searchUrl}`);
    this.setState({ value: "" });
    this.onBlur();
  };

  // searchValue = debounce((value, origin) => {

  //   this.onFocus();
  //   this.setState({ value });

  //   if (value !== "") {
  //     fetch(`http://localhost:8080/searchapi/${value}?lang=${this.state.lang}`)
  //       .then(results => results.json())
  //       .then(data => {
  //         this.setState({ data });
  //         console.log('header fetching: ', `http://localhost:8080/searchapi/${value}?lang=${this.state.lang}`, ' \n data: ', data)
  //       }).catch(error => {
  //         console.log('error on search ', error)
  //       });
  //   } else {
  //     this.setState({ data: "" });
  //   }
  // }, 500);

  searchValue = async (value, origin) => {
    this.onFocus();
    this.setState({ value });

    if (value !== "") {
      await fetch(`${origin}/searchapi/${value}?lang=${this.state.lang}`)
        .then(response => response.json())
        .then(async data => {
          await this.setState({ data });
          console.log('header fetching: ', `${origin}/searchapi/${value}?lang=${this.state.lang}`, ' \n data: ', data)
        })
        .catch(err => {
          console.log("error on search ", err);
        });
    } else {
      await this.setState({ data: "" });
    }
  };

  onBlur() {
    this.setState({ inputValue: this.state.value });
    setTimeout(() => {
      this.setState({ hide: "hidden" });
    }, 100);
  }

  onFocus() {
    this.setState({ hide: "" });
  }

  handleMobileChange(e) {
    e.preventDefault();

    let target = e.target.value;
    let currentLang = this.state.lang;
    let currentPathName = window.location.pathname;
    let newPath;

    if (this.props.path) {
      newPath = this.props.path.slice(0, -2);
      newPath = `${newPath}${target}`;
      window.location.replace(`${SiteConfig.url}/search${newPath}`);
    } else {
      if (currentPathName === "/" || currentPathName.length <= 4) {
        if (target !== "en") {
          newPath = `/${target}/`;
        } else {
          newPath = "/";
        }
      } else if (currentLang === "en" && currentPathName.length > 4) {
        if (target !== "en") {
          newPath = `/${target}${currentPathName}`;
        }
      } else {
        if (target !== "en") {
          newPath = currentPathName.replace(`/${currentLang}/`, `/${target}/`);
        } else {
          newPath = "/" + currentPathName.substring(4);
        }
      }
    }
    navigate(newPath);
  }

  render() {
    let lang = this.state.lang;
    const options = [
      { value: "en", label: "English" },
      { value: "de", label: "Deutsch‎" },
      { value: "ru", label: "Русский" },
      { value: "pt", label: "Português" },
      { value: "it", label: "Italiano‎" },
      { value: "es", label: "Español‎" },
      { value: "ja", label: "日本語‎" },
      { value: "fr", label: "Français" },
      { value: "tr", label: "Türk" },
      { value: "pl", label: "Polskie" },
      { value: "nl", label: "Dutch" },
      { value: "cs", label: "Český" },
      { value: "el", label: "Ελληνικά" },
      { value: "fi", label: "Finnish Suomalainen" },
      { value: "hi", label: "हिंदी" },
      { value: "hr", label: "Hrvatski" },
      { value: "hu", label: "Magyar" },
      { value: "id", label: "Indonesia" },
      { value: "ko", label: "한국어" },
      { value: "nb", label: "Norsk" },
      { value: "he", label: "Hebrew" },
      { value: "ar", label: "Arabic" },
      { value: "zh", label: "中文" }
    ];

    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <BackDrop click={this.backdropClickHandler} />;
    }

    return (
      <Jumbotron
        style={{
          backgroundImage: SiteConfig.content.header.style.backgroundImage,
          backgroundColor: SiteConfig.content.header.style.backgroundColor
        }}
        className={
          SiteConfig.brand === "Blix" ? "site-header-blix" : "site-header"
        }
      >
        <SideDrawer
          show={this.state.sideDrawerOpen}
          drawerToggleClickHandler={this.drawerToggleClickHandler}
          handleMobileChange={this.handleMobileChange}
          lang={this.state.lang}
          json={this.props.json}
          activeTab={this.props.activeTab}
        />
        {backdrop}
        <div className="text-white text-center container">
          <Navbar light expand="md">
            <Link
              className="navbar-brand"
              to={lang === "en" ? "/" : `/${lang}/`}
            >
              <img src={SiteConfig.logo} alt={`${SiteConfig.brand} Logo`} />
            </Link>
            <NavbarToggler onClick={() => this.drawerToggleClickHandler()} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar style={{ alignItems: "center" }}>
                {SiteConfig.content.header.menu.length > 0 &&
                  SiteConfig.content.header.menu.map((nav, i) => (
                    <NavItem key={i}>
                      <Link
                        className="text-white"
                        to={nav.href}
                        style={{ padding: "0.5rem 1rem" }}
                      >
                        {nav.name}
                      </Link>
                    </NavItem>
                  ))}
                {!this.props.searchHide && (
                  <NavItem key={"lang-select"} className="lang-select">
                    <Dropdown
                      options={options}
                      value={lang}
                      onChange={this.handleChange}
                    />
                  </NavItem>
                )}
                {!this.props.searchHide && (
                  <NavItem className="lang-select-mobile">
                    <Input
                      type="select"
                      name="select"
                      value={lang}
                      onChange={this.handleMobileChange}
                      style={{
                        width: "50%",
                        margin: "5px auto 0 auto",
                        background: "none",
                        color: "white"
                      }}
                    >
                      <option value="en">English</option>
                      <option value="de">Deutsch‎</option>
                      <option value="ru">Русский</option>
                      <option value="pt">Português</option>
                      <option value="it">Italiano‎</option>
                      <option value="es">Español‎</option>
                      <option value="ja">日本語‎</option>
                      <option value="fr">Français</option>
                      <option value="tr">Türk</option>
                      <option value="pl">Polskie</option>
                      <option value="nb">Norsk</option>
                      <option value="nl">Dutch</option>
                      <option value="cs">Český</option>
                      <option value="el">Ελληνικά</option>
                      <option value="fi">Finnish Suomalainen</option>
                      <option value="hi">हिंदी</option>
                      <option value="hr">Hrvatski</option>
                      <option value="hu">Magyar</option>
                      <option value="id">Indonesia</option>
                      <option value="ko">한국어</option>
                      <option value="he">Hebrew</option>
                      <option value="ar">Arabic</option>
                      <option value="zh">中文</option>
                    </Input>
                  </NavItem>
                )}
              </Nav>
            </Collapse>
          </Navbar>
          {!this.props.searchHide && (
            <div>
              <h1
                onClick={() => {
                  lang !== "en" ? navigate(`/${lang}/`) : navigate("/");
                }}
                style={{ cursor: "pointer" }}
                className="display-4"
              >
                {this.state.text.title}
              </h1>
              <p className="header-sub-title">
                {this.state.text.subTitle.replace(/@brand/g, SiteConfig.brand)}
              </p>
              <div className="d-flex justify-content-center mx-auto autocomplete">
                <Input
                  style={{
                    border: "none",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px"
                  }}
                  className="search-input p-4"
                  onKeyPress={this.handleKeyPress}
                  onChange={e => {
                    this.searchValue(e.target.value, window.location.origin);
                    console.log(window.location.origin);
                  }}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  placeholder={this.state.text.search}
                />
                <button
                  className="submit-btn"
                  type="submit"
                  onClick={this.handleSearchButton}
                >
                  <FA name="fas fa-search" />
                </button>

                { this.state.data && this.state.data.length > 0 && (
                  <ListGroup className={`autocomplete-list ${this.state.hide}`}>
                    {this.state.data.map((item, i) => {
                      if (i < 10) {
                        return (
                          <Link to={item.url} key={item.url + i} className="autocomplete-list__item d-flex" onClick={() => console.log(item.url)}>
                          <FA
                            name="angle-double-right"
                            className="arrow-icon"
                          />
                          {item.subject}
                          </Link>
                        );
                      }
                      return "";
                    })}
                  </ListGroup>
                )}
              </div>
            </div>
          )}
        </div>
        <CookieConsent
          location="bottom"
          cookieName="CookieConsent"
          style={{ background: "#2B373B", fontSize: "15px" }}
          buttonText={this.state.text.cookieBtn}
          buttonStyle={{
            color: "white",
            background: "#0B71BC",
            fontSize: "13px",
            margin: "0 100px 0 0",
            cursor: "pointer"
          }}
        >
          <span data-nosnippet>{this.state.text.cookieMsg}</span>
        </CookieConsent>
      </Jumbotron>
    );
  }
}
