export const en = {
    title: 'Help Center',
    subTitle: 'Everything you need to know about making @brand work for you',
    search: 'How can we help you?',
    cookieMsg: 'This site uses cookies to assist with navigation and your ability to provide feedback, analyze your use of our products and services.',
    cookieBtn: 'I understand'
  }
 
  export const de = {
   title: 'Hilfezentrum',
   subTitle: 'Alles, was Sie wissen müssen, um @brand zu machen, arbeiten für Sie',
   search: 'Suche',
   cookieMsg: 'Diese Website verwendet Cookies, um Sie bei der Navigation zu unterstützen und Ihnen die Möglichkeit zu geben, Feedback zu geben und Ihre Nutzung unserer Produkte und Dienstleistungen zu analysieren.',
   cookieBtn: 'Ich verstehe'
 }
 
 export const es = {
   title: 'Centro De Ayuda',
   subTitle: 'Todo lo que necesita saber sobre cómo hacer que @brand trabaje para usted',
   search: 'Buscar',
   cookieMsg: 'Este sitio utiliza cookies para ayudar con la navegación y su capacidad para proporcionar comentarios, analizar el uso de nuestros productos y servicios.',
   cookieBtn: 'Entiendo'
 }
 
 export const ru = {
   title: 'Центр Помощи',
   subTitle: 'Все, что вам нужно знать о том, как заставить @brand работать для вас',
   search: 'Поиск',
   cookieMsg: 'На этом сайте используются куки-файлы, чтобы помочь с навигацией, а также возможность предоставлять обратную связь, анализировать использование наших продуктов и услуг.',
   cookieBtn: 'понимаю'
 }
 
 export const pt = {
   title: 'Centro de Ajuda',
   subTitle: 'Tudo o que você precisa saber para fazer o @brand funcionar para você',
   search: 'Pesquisa',
   cookieMsg: 'Este site usa cookies para auxiliar na navegação e sua capacidade de fornecer feedback, analisar seu uso de nossos produtos e serviços.',
   cookieBtn: 'Compreendo'
 }
 
 export const ja = {
   title: 'ヘルプセンター',
   subTitle: 'あなたが@brandあなたのために働くことについて知る必要があるすべて',
   search: 'サーチ',
   cookieMsg: 'このサイトでは、クッキーを使用してナビゲーションやフィードバックの提供、商品やサービスの使用状況の分析などを支援しています。',
   cookieBtn: 'わかりました'
 }
 
 export const fr = {
   title: "Centre D'aide",
   subTitle: 'Tout ce que vous devez savoir sur la création de @brand pour vous',
   search: 'Chercher',
   cookieMsg: 'Ce site utilise des cookies pour faciliter la navigation et votre capacité à fournir des commentaires, à analyser votre utilisation de nos produits et services.',
   cookieBtn: 'je comprends'
 }
 //italian
 export const it = {
   title: 'Centro Assistenza',
   subTitle: 'Tutto quello che devi sapere su come far funzionare @brand per te',
   search: 'Ricerca',
   cookieMsg: "Questo sito utilizza i cookie per aiutare con la navigazione e la tua capacità di fornire feedback, analizzare l'utilizzo dei nostri prodotti e servizi.",
   cookieBtn: 'Capisco'
 }
 //Czech
 export const cs = {
    title: 'Centrum nápovědy',
    subTitle: 'Vše, co potřebujete vědět o tom, že vám @brand pracuje',
    search: 'Jak vám můžeme pomoci?',
    cookieMsg: 'Tyto stránky využívají soubory cookie, které vám pomohou s navigací a schopností poskytovat zpětnou vazbu, analyzovat vaše používání našich produktů a služeb.',
    cookieBtn: 'Chápu'
 }
 //Greek Ελληνικά
 export const el = {
   title: 'Κέντρο βοηθείας',
   subTitle: 'Όλα όσα χρειάζεται να γνωρίζετε για την πραγματοποίηση της @brand εργασίας για εσάς',
   search: 'Ψάξιμο',
   cookieMsg: 'Αυτός ο ιστότοπος χρησιμοποιεί cookies για να βοηθήσει στην πλοήγηση και την ικανότητά σας να παρέχετε ανατροφοδότηση, να αναλύετε τη χρήση των προϊόντων και των υπηρεσιών σας.',
   cookieBtn: 'καταλαβαίνω'
 }
 //Finnish Suomalainen
 export const fi = {
   title: 'Ohjekeskus',
   subTitle: 'Kaikki mitä sinun tarvitsee tietää tehdä @brand työtä sinulle',
   search: 'Hae',
   cookieMsg: 'Tämä sivusto käyttää evästeitä, jotka auttavat navigointia ja kykyä antaa palautetta, analysoida tuotteiden ja palveluiden käyttöä.',
   cookieBtn: 'Ymmärrän'
 }
 //hindi हिंदी
 export const hi = {
   title: 'सहायता केंद्र',
   subTitle: 'आपके लिए @brand काम करने के बारे में आपको जो कुछ पता होना चाहिए',
   search: 'हम आपकी किस प्रकार मदद कर सकते हैं?',
   cookieMsg: 'यह साइट नेविगेशन और फीडबैक प्रदान करने की आपकी क्षमता, हमारे उत्पादों और सेवाओं के उपयोग का विश्लेषण करने में सहायता के लिए कुकीज़ का उपयोग करती है।',
   cookieBtn: 'मै समझता हुँ'
 }
 //Croatian Hrvatski
 export const hr = {
   title: 'Centar za pomoć',
   subTitle: 'Sve što trebate znati o izradi @brand radi za vas',
   search: 'Kako vam možemo pomoći?',
   cookieMsg: 'Ova web stranica koristi kolačiće za pomoć pri navigaciji i vašoj sposobnosti da daju povratne informacije, analiziraju vašu upotrebu naših proizvoda i usluga.',
   cookieBtn: 'razumijem'
 }
 
 //Hungarian Magyar
 export const hu = {
   title: 'Segítség Központ',
   subTitle: 'Minden, amit tudnia kell arról, hogy az @brand dolgozik az Ön számára',
   search: 'Miben segíthetünk?',
   cookieMsg: 'Ez az oldal cookie-kat használ a navigációhoz és a visszajelzést nyújtó képességéhez, elemzi termékeink és szolgáltatásaink használatát.',
   cookieBtn: 'Megértem'
 }
 
 //Indonesian Indonesia
 export const id = {
   title: 'Pusat Bantuan',
   subTitle: 'Segala sesuatu yang perlu Anda ketahui tentang membuat @brand bekerja untuk Anda',
   search: 'Bagaimana kami bisa membantu Anda?',
   cookieMsg: 'Situs ini menggunakan cookie untuk membantu navigasi dan kemampuan Anda untuk memberikan umpan balik, menganalisis penggunaan Anda atas produk dan layanan kami.',
   cookieBtn: 'saya mengerti'
 }
 
 //Korean 한국어
 export const ko = {
   title: '지원 센터',
   subTitle: '@brand 제작에 대해 알아야 할 모든 것',
   search: '어떻게 도와 드릴까요?',
   cookieMsg: '이 사이트는 쿠키를 사용하여 탐색 및 피드백 제공 능력을 지원하고 제품 및 서비스 사용을 분석합니다.',
   cookieBtn: '이해 했어'
 }
 //Norwegian Norsk
 export const nb = {
   title: 'Hjelpesenter',
   subTitle: 'Alt du trenger å vite om å lage @brand jobber for deg',
   search: 'Hvordan kan vi hjelpe deg?',
   cookieMsg: 'Dette nettstedet bruker informasjonskapsler for å bistå med navigasjon og din evne til å gi tilbakemelding, analysere bruken av våre produkter og tjenester.',
   cookieBtn: 'jeg forstår'
 }
 
 //Dutch
 export const nl = {
   title: 'Helpcentrum',
   subTitle: 'Alles wat u moet weten over het maken van @brand werkt voor u',
   search: 'Hoe kunnen wij u helpen?',
   cookieMsg: 'Deze website maakt gebruik van cookies om u te helpen bij het navigeren en uw mogelijkheid om feedback te geven, en analyseert uw gebruik van onze producten en diensten.',
   cookieBtn: 'Ik begrijp het'
 }
 
 //Polish Polskie
 export const pl = {
   title: 'Centrum pomocy',
   subTitle: 'Wszystko, co musisz wiedzieć o tworzeniu @brand, działa dla Ciebie',
   search: 'Jak możemy ci pomóc?',
   cookieMsg: 'jego strona korzysta z plików cookie w celu ułatwienia nawigacji i przekazywania opinii, analizy korzystania z naszych produktów i usług.',
   cookieBtn: 'rozumiem'
 }
 
 //Romanian Română
 export const ro = {
   title: 'Centru de ajutor',
   subTitle: 'Tot ce trebuie să știți despre a face lucrul @brand pentru tine',
   search: 'Cum vă putem ajuta?',
   cookieMsg: 'Acest site utilizează cookie-uri pentru a vă ajuta cu navigarea și pentru a vă oferi feedback, pentru a vă analiza utilizarea produselor și serviciilor noastre.',
   cookieBtn: 'inteleg'
 }
 
 //Slovak Slovenský
 export const sk = {
   title: 'Centrum pomoci',
   subTitle: 'Všetko, čo potrebujete vedieť o tom, ako robiť @brand prácu pre vás',
   search: 'Ako vám môžeme pomôcť?',
   cookieMsg: 'Táto stránka využíva súbory cookie na pomoc s navigáciou a vašu schopnosť poskytovať spätnú väzbu, analyzovať používanie našich produktov a služieb.',
   cookieBtn: 'rozumiem'
 }
 
 //Swedish svenska
 export const sv = {
   title: 'Hjälpcenter',
   subTitle: 'Allt du behöver veta om att @brand arbetar för dig',
   search: 'Hur kan vi hjälpa dig?',
   cookieMsg: 'Denna sida använder cookies för att hjälpa till med navigering och din förmåga att ge feedback, analysera din användning av våra produkter och tjänster.',
   cookieBtn: 'jag förstår'
 }
 //Thai ไทย
 export const th = {
   title: 'ศูนย์ช่วยเหลือ',
   subTitle: 'ทุกสิ่งที่คุณจำเป็นต้องรู้เกี่ยวกับการทำให้ @brand ทำงานได้ดีสำหรับคุณ',
   search: 'เราจะช่วยคุณได้อย่างไร?',
   cookieMsg: 'ไซต์นี้ใช้คุกกี้เพื่อช่วยในการนำทางและความสามารถในการให้ข้อเสนอแนะวิเคราะห์การใช้ผลิตภัณฑ์และบริการของเรา',
   cookieBtn: 'ฉันเข้าใจ'
 }
 //Filipino Filipino
 export const tl = {
   title: 'Tulungan Gitna',
   subTitle: 'Lahat ng kailangan mong malaman tungkol sa paggawa ng @brand ay gumagana para sa iyo',
   search: 'Paano namin kayo matutulungan?',
   cookieMsg: 'Ang site na ito ay gumagamit ng cookies upang makatulong sa pag-navigate at ang iyong kakayahang magbigay ng feedback, pag-aralan ang iyong paggamit ng aming mga produkto at serbisyo.',
   cookieBtn: 'naiintindihan ko'
 }
 //Turkish Türk
 export const tr = {
   title: 'Yardım Merkezi',
   subTitle: '@brand sizin için çalışmasını bilmeniz gereken her şey',
   search: 'Nasıl yardım edebiliriz?',
   cookieMsg: 'Bu site gezinme ve geri bildirim sağlama yeteneğiniz, ürünlerimizi ve hizmetlerimizi kullanımınızı analiz etmenize yardımcı olacak çerezler kullanmaktadır.',
   cookieBtn: 'anlıyorum'
 }
 //Vietnamese Tiếng Việt
 export const vi = {
   title: 'Trung tâm trợ giúp',
   subTitle: 'Tất cả mọi thứ bạn cần biết về việc tạo @brand làm việc cho bạn',
   search: 'Làm thế nào chúng tôi có thể giúp bạn?',
   cookieMsg: 'Trang web này sử dụng cookie để hỗ trợ điều hướng và khả năng cung cấp phản hồi của bạn, phân tích việc bạn sử dụng các sản phẩm và dịch vụ của chúng tôi.',
   cookieBtn: 'tôi hiểu'
 }
 //Chinese 中文
 export const zh = {
   title: '帮助中心',
   subTitle: '关于让@brand为您工作所需的一切知识',
   search: '我们该怎样帮助你？',
   cookieMsg: '本网站使用cookie来协助导航，并提供反馈，分析您对我们产品和服务的使用。',
   cookieBtn: '我明白'
 }

 export const he = {
  title: 'מרכז עזרה',
  subTitle: ' לעבוד בשבילך @brand כל מה שאתה צריך לדעת כדי לגרום ל',
  search: 'איך נוכל לעזור לך?',
  cookieMsg: 'אתר זה משתמש בעוגיות כדי לסייע בניווט וביכולתך לספק משוב, לנתח את השימוש שלך במוצרים ובשירותים שלנו.',
  cookieBtn: 'אני מבין'
}

export const ar = {
  title: 'مركز المساعدة',
  subTitle: 'كل ما تحتاج لمعرفته حول جعل @brand يعمل من أجلك',
  search: 'كيف يمكننا مساعدتك؟',
  cookieMsg: 'يستخدم هذا الموقع ملفات تعريف الارتباط للمساعدة في التنقل وقدرتك على تقديم الملاحظات وتحليل استخدامك لمنتجاتنا وخدماتنا.',
  cookieBtn: 'اني اتفهم'
}